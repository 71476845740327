import utils from '@common/utils';

const fullMenus = [
  {
    title: '首页',
    key: 'Home',
    icon: 'icon-monitor',
    // count: 1
  },
  {
    title: '增加乐趣',
    key: 'add',
    icon: 'icon-square-plus'
  },
  {
    title: '娱乐列表',
    key: 'list',
    icon: 'icon-clipboard'
  },
  {
    title: '我的伙伴',
    key: 'user',
    icon: 'icon-umbrella'
  },
  {
    title: '玩耍记录',
    key: 'log',
    icon: 'icon-file'
  },
  // {
  //   title: 'Icons',
  //   key: 'Icons',
  //   icon: 'icon-heart'
  // },
  // {
  //   title: '列表应用',
  //   key: 'TableList',
  //   icon: 'icon-grid-2',
  //   children: [
  //     {
  //       title: '基础表格',
  //       key: 'TableBasic'
  //     },
  //     {
  //       title: '查询列表',
  //       key: 'TableSearch'
  //     }
  //   ]
  // },
  // {
  //   title: '表单应用',
  //   key: 'FormFolder',
  //   icon: 'icon-paper',
  //   children: [
  //     {
  //       title: '基础表单',
  //       key: 'Form'
  //     },
  //     {
  //       title: '表单详情',
  //       key: 'FormDetail'
  //     }
  //   ]
  // },
  // {
  //   title: '模糊匹配',
  //   key: 'AutoCompleteFolder',
  //   icon: 'icon-disc',
  //   children: [
  //     {
  //       title: '模糊搜索',
  //       key: 'Autocomplete1'
  //     },
  //     {
  //       title: '场景应用',
  //       key: 'Autocomplete2'
  //     },
  //     {
  //       title: '复杂场景',
  //       key: 'Autocomplete3'
  //     }
  //   ]
  // },
  // {
  //   title: '扩展组件',
  //   key: 'Advance-folder',
  //   icon: 'icon-bar-graph-2',
  //   children: [
  //     {
  //       title: '图表',
  //       key: 'Chart'
  //     },
  //     {
  //       title: '富文本编辑器',
  //       key: 'RicktextEditor'
  //     },
  //     {
  //       title: '代码编辑器',
  //       key: 'CodeEditor'
  //     },
  //     {
  //       title: 'Markdown编辑器',
  //       key: 'MarkdownEditor'
  //     },
  //     {
  //       title: '百度地图',
  //       key: 'BaiduMap'
  //     }
  //   ]
  // },
  // {
  //   title: '系统设置',
  //   key: 'SysSetting',
  //   icon: 'icon-cog',
  //   children: [
  //     {
  //       title: '个人中心',
  //       key: 'AccountBasic'
  //     },
  //     {
  //       title: '安全设置',
  //       key: 'SecuritySetting'
  //     },
  //     {
  //       title: '权限设置',
  //       key: 'Authorization'
  //     }
  //   ]
  // },
  // {
  //   title: '错误页面',
  //   key: 'ErrorPages',
  //   icon: 'icon-ban',
  //   children: [
  //     {
  //       title: '系统错误',
  //       key: 'SystemError'
  //     },
  //     {
  //       title: '权限错误',
  //       key: 'PermissionError'
  //     },
  //     {
  //       title: '找不到页面',
  //       key: 'NotfoundError'
  //     }
  //   ]
  // }
];

const getMenus = function (menuIdList = []) {
  return getAuthMenu(fullMenus, menuIdList);
};

let getAuthMenu = (menus, menuIdList) => {
  let configMenu = [];
  for (let menu of menus) {
    let m = utils.copy(menu);
    if (menuIdList.indexOf(m.key) > -1) {
      configMenu.push(m);
    }
    if (menu.children && menu.children.length) {
      m.children = getAuthMenu(menu.children, menuIdList);
    }
  }
  return configMenu;
};

const getKeys = function (menus) {
  let keys = [];
  for (let menu of menus) {
    keys.push(menu.key);
    if (menu.children && menu.children.length) {
      keys.push(...getKeys(menu.children));
    }
  }
  return keys;
};

let fullMenuKeys = getKeys(fullMenus);

const isAuthPage = function (menus, name) {
  if (fullMenuKeys.indexOf(name) > -1 && menus.indexOf(name) == -1) {
    return false;
  }
  return true;
};

export { getMenus, fullMenus, fullMenuKeys, isAuthPage };
